import { Injectable, Inject } from '@angular/core';

import { Subject } from 'rxjs';
import { FarmFilter } from '@shared/core';

@Injectable({
  providedIn: 'root'
})
export class FarmFilterService {
  private subject$: Subject<FarmFilter> = new Subject();
  private currentSelectedFarmFilter: FarmFilter;

  constructor(
      @Inject('JsFarmFilterService') private jsFarmFilterService: any
  ) {
    this.jsFarmFilterService.get$()
        .subscribe((farmFilter: FarmFilter) => {
            this.currentSelectedFarmFilter = farmFilter;
            this.subject$.next(farmFilter);
        });
  }

  get(): Subject<FarmFilter> {
    return this.subject$;
  }

  getCurrentSelectedFarmFilter(): FarmFilter {
    return this.currentSelectedFarmFilter;
  }
}
