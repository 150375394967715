import {UploadedFileModel} from './uploaded-file.model';

export class UploadFileDetails implements UploadedFileModel {
  file: File;
  url: string;
  // tslint:disable-next-line: variable-name
  file_name: string;

  public equals(obj: any): boolean {
    return obj.url === this.url || obj.file_name === this.file_name;
  }
}
