import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WeatherService } from './services/weather.service';
import { MaterialModule } from '@shared';
import { FlexLayoutModule } from '@angular/flex-layout';
import { WindDirectionService } from './services/wind-direction.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { WeatherCardComponent } from './weather-card/weather-card.component';
import { WeatherDetailDialogComponent } from './weather-detail-dialog/weather-detail-dialog.component';
import { EmptyStateModule } from '@shared/components/empty-state/empty-state.module';
import { WeatherDetailTableComponent } from './weather-detail-table/weather-detail-table.component';
import { WeatherDetailChartComponent } from './weather-detail-chart/weather-detail-chart.component';
import { HighchartsChartModule } from 'highcharts-angular';
import { WeatherParameterService } from './services/weather-parameter.service';
import { FormsModule } from '@angular/forms';
import { DailyWeatherViewerComponent } from './daily-weather-viewer/daily-weather-viewer.component';
import { WeatherForecastComponent } from './weather-forecast/weather-forecast.component';
import { WeatherForecastCardComponent } from './weather-forecast-card/weather-forecast-card.component';

@NgModule({
  declarations: [
    WeatherForecastCardComponent,
    WeatherCardComponent,
    WeatherDetailDialogComponent,
    WeatherDetailTableComponent,
    WeatherDetailChartComponent,
    DailyWeatherViewerComponent,
    WeatherForecastComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    FlexLayoutModule,
    BrowserModule,
    BrowserAnimationsModule,
    EmptyStateModule,
    HighchartsChartModule
  ],
  exports: [WeatherForecastCardComponent],
  providers: [WeatherService, WindDirectionService, WeatherParameterService],
  entryComponents: [
    WeatherForecastCardComponent,
    WeatherDetailDialogComponent,
    WeatherForecastComponent
  ]
})
export class WeatherModule {}
