import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { LocationType, WeatherResponse } from '@weather/models';
import { WeatherService } from '@weather/services/weather.service';

@Component({
  selector: 'app-weather-forecast',
  template: require('./weather-forecast.component.html'),
  styles: [require('./weather-forecast.component.scss')]
})
export class WeatherForecastComponent implements OnInit, OnChanges {
  weather: WeatherResponse;
  location: string;
  status: string;
  @Input()
  slug: string;

  @Input()
  name: string;

  @Input()
  type: LocationType;

  @Input()
  days: number;

  readonly DEFAULT_FORECAST_DAYS = 4;

  constructor(private weatherService: WeatherService) {}

  ngOnInit() {}

  ngOnChanges() {
    this.location = 'Local weather for ' + this.name + this.status;
    this.status = '';
    if (this.slug && this.type) {
      const days = this.days === undefined || this.days === null ? this.DEFAULT_FORECAST_DAYS : this.days;
      this.weatherService
        .getForecast(this.type, this.slug, days)
        .subscribe(
            weather => (this.weather = weather),
            error => (this.status = 'not available.'));
    }
  }
}
