import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import { Site } from '../models';

@Injectable()
export class SitesService {

  constructor(private http: HttpClient) { }

  getSites(): Observable<Array<Site>> {
    return this.http.get<Array<Site>>('/api/v1.0/khcore/sites/');
  }
}
