import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'padStart'
})
export class PadStartPipe implements PipeTransform {

  transform(value: string, targetLength: number, padString?: string): any {
    let res = '';
    if (value) {
      res = String(value);
    }
    return res.padStart(targetLength, padString);
  }

}
