import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ProductSummaryResponse } from '@shared/core/models/product-summary-response';
import { ProductSummary } from '@shared/core/models/product-summary';

@Injectable({
  providedIn: 'root'
})
export class ProductsService {
  constructor(private http: HttpClient) {}

  getPesticideSummary(id: number): Observable<ProductSummary> {
    return this.http.get<ProductSummary>(`api/product/${id}`);
  }

  getPesticideSummaries(search?: string): Observable<ProductSummaryResponse> {
    let params = new HttpParams().set('type', 'pesticide');
    if (search) {
      params = params.append('search', search);
    }
    return this.http.get<ProductSummaryResponse>('api/product/', { params });
  }
}
