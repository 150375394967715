import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-empty-state',
  template: require('./empty-state.component.html'),
  styles: [require('./empty-state.component.scss')]
})
export class EmptyStateComponent implements OnInit {
  @Input() message: string;

  constructor() { }

  ngOnInit() {
  }

}
