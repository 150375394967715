import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {KeyValue} from '@angular/common';

import {
  ApplicationService,
  CheckClientAndTenantService,
  Country,
  CountryService,
  ErrorHandlerService,
  PlatformSettings,
  PlatformSettingsService,
  Subscription,
  SubscriptionService,
  Team,
  TeamService
} from '@shared/core';
import {Observable, of, Subject} from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  startWith,
  switchMap,
  takeUntil,
  tap
} from 'rxjs/operators';
import {PersonalRelationship, PersonalRelationshipService} from '@settings/shared';
import {InviteUserService} from '../services';
import {MatSnackBar} from '@angular/material';
import {Application} from '@shared/core/models/application.model';
import {HttpErrorResponse} from '@angular/common/http';

@Component({
  selector: 'app-invite-user',
  template: require('./invite-user.component.html'),
  styles: [require('./invite-user.component.scss')]
})
export class InviteUserComponent implements OnInit, OnDestroy {
// tslint:disable-next-line: max-line-length
  private Emailregex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  private alphabetsValidator = Validators.pattern(/^[A-Za-z\s]+$/);

  inviteForm = this.fb.group({
    firstname: ['', [Validators.required, this.alphabetsValidator]],
    lastname: ['',  [Validators.required, this.alphabetsValidator]],
    email: ['',  [Validators.required, Validators.pattern(this.Emailregex)]],
    country_name: ['', [this.alphabetsValidator]],
    country: [''],
    dial_code: [{value: '', disabled: true}],
    mobile: ['', Validators.pattern(/^[0-9]+$/)],
    primary_relation_name: ['', this.alphabetsValidator],
    nature_of_relationship: [''],
    memberships: this.fb.group({}),
    primary_team: ['', [Validators.required, Validators.pattern(/^[\d]+$/)]],
    make_team_admin: [ false ]
  });

  countries: Array<Country> = [];
  filteredCountries: Observable<Array<Country>>;
  teams: Array<Team> = [];
  personalRelationship: Observable<Array<PersonalRelationship>>;
  availableMemberships: Array<string> = [];
  private unsubscribeObservables$: Subject<void> = new Subject();
  savingUser = false; // Used for disabling Invite button and displaying progress bar while saving user
  fetchingTeams = false;
  addressFieldsForIndia = {
    taluka: [Validators.required, this.alphabetsValidator],
    district: [Validators.required, this.alphabetsValidator],
    state: this.alphabetsValidator,
    village: this.alphabetsValidator,
    pin_code: [Validators.required, Validators.pattern(/^[0-9]{6}$/)]
  };
  clientId = '';
  tenantId = '';
  listOfApplications: Observable<Array<Application>>;
  subscriptionPackages: Observable<Array<Subscription>>;
  isActivationByMobile = false;

  constructor(
    private countryService: CountryService,
    private teamService: TeamService,
    private personalRelationshipService: PersonalRelationshipService,
    private platformSettingService: PlatformSettingsService,
    private inviteUserService: InviteUserService,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<InviteUserComponent>,
    private snackBar: MatSnackBar,
    private errorHandlerService: ErrorHandlerService,
    private checkClientTenantService: CheckClientAndTenantService,
    private applicationsService: ApplicationService,
    private subscriptionService: SubscriptionService,
    @Inject(MAT_DIALOG_DATA) public dialogData: any
  ) { }

  ngOnInit() {
    this.tenantId = this.dialogData.tenantId;

    // Add 'List of Applications' and 'Subscription packages' field into the form for NIAB
    if (this.isTenantNiab()) {
      this.loadListOfApplications();
      this.loadSubscriptionPackages();
      this.inviteForm.addControl('list_of_applications', new FormControl('', Validators.required));
      this.inviteForm.addControl('subscription_package', new FormControl('', Validators.required));
    }

    this.loadAvailableMembershipsAndClientId();
    this.loadCountries();
    this.fetchTeams();
    this.loadPersonalRelationship();
  }


  addExtraFieldsforIndia() {
    // tslint:disable-next-line: forin
    for (const key in this.addressFieldsForIndia) {
      this.inviteForm.addControl(key, new FormControl('', this.addressFieldsForIndia[key]));
    }
  }


  save(): void {
    if (!this.inviteForm.valid) {
      return;
    }

    this.savingUser = true;
    const formData = this.inviteForm.getRawValue();
    Object.assign(formData, formData.memberships);
    delete formData.memberships;

    for (const key in formData) {
      if (!formData[key]) {
        delete formData[key];
      }
    }
    this.inviteUserService.inviteUser(formData)
      .subscribe((res) => {
        this.savingUser = false;
        this.dialogRef.close();
        this.snackBar.open('Invitation sent', 'CLOSE');
      },
      (err) => {
        this.errorHandlerService.errorHandler(err);
        this.savingUser = false;
      });
  }


  closeDialog(): void {
    this.dialogRef.close();
  }


  fetchTeams(): void {
    this.inviteForm.get('primary_team').valueChanges
      .pipe(
        takeUntil(this.unsubscribeObservables$),
        filter((val => isNaN(val))),
        filter(val => val && val.length >= 3),
        debounceTime(500),
        distinctUntilChanged(),
        tap(() => this.fetchingTeams = true),
        switchMap((val: string) => this.teamService.getLegacyTeams(val)),
        tap(() => this.fetchingTeams = false)
      ).subscribe(
        (teams: Array<Team>) => this.teams = teams,
        this.errorHandlerService.errorHandler
      );
  }


  isClientIndia(): boolean {
    return this.checkClientTenantService.isClientIndia(this.clientId);
  }


  isClientUK(): boolean {
    return this.checkClientTenantService.isClientUK(this.clientId);
  }


  isTenantNiab() {
    return this.checkClientTenantService.isTenantNiab(this.tenantId);
  }


  loadAvailableMembershipsAndClientId(): void {
    this.platformSettingService.getAvailableSettings()
      .subscribe((val: PlatformSettings) => {
        this.clientId = val.CLIENT_ID;
        if (val.MOBILE_ACTIVATION_SETTING) {
          this.isActivationByMobile = val.MOBILE_ACTIVATION_SETTING;
          this.inviteForm.get('country_name').setValidators([Validators.required, this.alphabetsValidator]);
          this.inviteForm.get('mobile').setValidators([Validators.required, Validators.pattern(/^[0-9]+$/)]);
          this.inviteForm.get('email').setValidators(Validators.pattern(this.Emailregex));
        }

        if (this.checkClientTenantService.isClientIndia(val.CLIENT_ID)) {
          this.addExtraFieldsforIndia();
        }

        const availableMemberships = val.MEMBERSHIPS.slice(1, -1).split(',');
        const formControls = {};
        availableMemberships.forEach((membership: string) => {
          membership = membership.trim().slice(1, -1);
          formControls['member_id' + membership] = ['', Validators.pattern(/^[\d]+$/)];
          this.availableMemberships.push(membership);
        });

        const membershipFormFields: FormGroup = this.fb.group(formControls);
        this.inviteForm.setControl('memberships', membershipFormFields);
      }, this.errorHandlerService.errorHandler);
  }


  loadCountries(): void {
    const filterCountry = (filterValue: string): Country[] =>
      this.countries.filter((countryObj) => countryObj.name.toLowerCase().includes(filterValue));

    this.countryService.getCountries()
      .subscribe((countries: Array<Country>) => {
        this.countries = countries;
        this.setCountryInFormInitially();
        this.filteredCountries = this.inviteForm.get('country_name').valueChanges
                                    .pipe(
                                      takeUntil(this.unsubscribeObservables$),
                                      startWith(''),
                                      map((value: string) => filterCountry(value.toLowerCase()))
                                    );
      }, this.errorHandlerService.errorHandler);
  }


  loadPersonalRelationship(): void {
    this.personalRelationship = this.personalRelationshipService.getPersonalRelationship();
  }


  loadListOfApplications(): void {
    this.listOfApplications = this.applicationsService.getApplications()
                                .pipe(catchError((err: HttpErrorResponse) => {
                                  this.errorHandlerService.errorHandler(err);
                                  return of([]);
                                }));
  }


  loadSubscriptionPackages(): void {
    this.subscriptionPackages = this.subscriptionService.getSubscriptions()
                                  .pipe(catchError((err: HttpErrorResponse) => {
                                    this.errorHandlerService.errorHandler(err);
                                    return of([]);
                                  }));
  }


  setCountryInFormInitially() {
    const getCountry = (countryName: string) =>
      this.countries.filter((countryObj) => countryObj.name.toLowerCase() === countryName.toLowerCase());

    if (this.isClientIndia()) {
      this.inviteForm.get('country_name').setValue('India');
      this.setDialCode(getCountry('India')[0]);
    }

    if (this.isClientUK()) {
      this.inviteForm.get('country_name').setValue('United Kingdom');
      this.setDialCode(getCountry('United Kingdom')[0]);
    }
  }


  /**
   * Set the dial coce and country code for the selected Country
   * @param item: User selected country
   */
  setDialCode(item: Country): void {
    this.inviteForm.get('dial_code').setValue(item.dial_code);
    this.inviteForm.get('country').setValue(item.country_code);
  }


  /**
   * Returns team name for the provided Team ID
   * This is required to display the Selected Team name in the Form instead of Team ID
   * @param teamId: Team ID selected by user
   */
  getTeamNameToDisplay = (teamId: string | number): string | undefined => {
    if (!this.teams) {
      return undefined;
    }
    const selectedTeam = this.teams.find((obj: Team) => obj.id === teamId);
    return selectedTeam ? selectedTeam.name : undefined;
  }


  orderMembershipsDescendingly = (a: KeyValue<string, string>, b: KeyValue<string, string>) => a.key > b.key;


  ngOnDestroy(): void {
    this.unsubscribeObservables$.next();
    this.unsubscribeObservables$.complete();
  }

}
