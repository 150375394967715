import { Component, OnInit, Input } from '@angular/core';
import { MatDialog } from '@angular/material';
import { UserDetailsModalComponent } from './user-details-modal/user-details-modal.component';

@Component({
  selector: 'app-user-details',
  template: require('./user-details.component.html'),
  styles: [require('./user-details.component.scss')]
})
export class UserDetailsComponent implements OnInit {
  @Input() userSlug: string;
  @Input() userName: string;

  constructor(
    public dialog: MatDialog,
  ) { }

  ngOnInit() {
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(UserDetailsModalComponent, {
      width: '700px',
      data: { userSlug: this.userSlug }
    });
  }

}
