import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Country } from '@shared/core/models';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable()

export class CountryService {

    constructor(private http: HttpClient) {
    }

    getCountries(): Observable<Country[]> {
        return this.http.get<Country[]>('/api/v1.0/profiles/countries/').pipe(map((result: any) => result.data));
    }
}
