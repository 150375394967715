import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SoilReportUpdateService {
  updatedReportId$: Subject<number> = new Subject<number>();

  constructor() {}

  soilReportAdded(id: number) {
    this.updatedReportId$.next(id);
  }
}
