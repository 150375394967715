import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '@shared/material';

import { FieldStatsChartComponent } from './field-stats-chart/field-stats-chart.component';
import { CoreModule } from '@shared/core';
import { FieldStatsService } from './services/field-stats.service';
import { FieldStatsChartStComponent } from '@shared/charts/field-stats/field-stats-chart-st/field-stats-chart-st.component';

@NgModule({
  declarations: [FieldStatsChartComponent, FieldStatsChartStComponent],
  imports: [CommonModule, MaterialModule, CoreModule],
  exports: [FieldStatsChartComponent, FieldStatsChartStComponent],
  entryComponents: [FieldStatsChartStComponent],
  providers: [FieldStatsService]
})
export class FieldStatsModule {}
