import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PlatformPermissionService } from './services/platform-permission.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: [
    PlatformPermissionService
  ]
})
export class PlatformPermissionModule { }
