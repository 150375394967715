import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-settings',
  template: require('./settings.component.html'),
  styles: [require('./settings.component.css')]
})
export class SettingsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
