import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgHttpLoaderModule } from 'ng-http-loader';
import { ProgressSpinnerComponent } from './progress-spinner/progress-spinner.component';
import { MaterialModule } from '@shared/material';
import { MatProgressBar } from '@angular/material';

@NgModule({
  declarations: [ProgressSpinnerComponent],
  imports: [
    CommonModule,
    NgHttpLoaderModule.forRoot(),
    MaterialModule
  ],
  exports: [ ProgressSpinnerComponent ],
  entryComponents: [ MatProgressBar ]
})
export class ProgressSpinnerModule { }
