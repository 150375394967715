import { Component, OnInit, OnDestroy, EventEmitter, Output, HostBinding } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { PlatformPermissionService, PlatformPermission, PlatformSettingsService, CheckClientAndTenantService } from '@shared/core';
import { MatDialog } from '@angular/material';
import { AddGroupComponent } from '../add-group';
import { InviteUserComponent } from '../invite-user';
import { OverlayContainer } from '@angular/cdk/overlay';
import { CreateTeamComponent } from '@settings/create-team/create-team/create-team.component';
import { PlatformSettings } from '@shared/core/models/platform-settings.model';

@Component({
  selector: 'app-secondary-navbar',
  template: require('./secondary-navbar.component.html'),
  styles: [require('./secondary-navbar.component.scss')]
})
export class SecondaryNavbarComponent implements OnInit, OnDestroy {
  @Output() addTeamClick: EventEmitter<void> = new EventEmitter<void>();
  @HostBinding('class') componentCssClass: string;
  tenantId: string;

  private unsubscribeObservables$: Subject<void> = new Subject();
  platformPermission: PlatformPermission;

  constructor(
    private platformPermissionService: PlatformPermissionService,
    public matDialog: MatDialog,
    private platformSettingsService: PlatformSettingsService,
    public overlayContainer: OverlayContainer,
    private checkClientTenantService: CheckClientAndTenantService) { }

  ngOnInit() {
    this.platformPermissionService.get()
    .pipe(takeUntil(this.unsubscribeObservables$))
    .subscribe((platformPermission: PlatformPermission) => {
      this.platformPermission = platformPermission;
    });

    this.platformSettingsService.getAvailableSettings().subscribe((val: PlatformSettings) => {
      this.tenantId = val.TENANT_ID;
      if (this.checkClientTenantService.isTenantNiab(this.tenantId)) {
        this.componentCssClass = this.checkClientTenantService.getTenantTheme(this.tenantId);
        this.overlayContainer.getContainerElement().classList.add(this.componentCssClass);
      }
    });
  }

  ngOnDestroy() {
    this.unsubscribeObservables$.next();
    this.unsubscribeObservables$.unsubscribe();
  }

  isAdmin(): boolean {
    if (this.platformPermission === null) {
      return false;
    }

    return this.platformPermission.isSuperUser || this.platformPermission.isTeamAdmin || this.platformPermission.isSiteAdmin;
  }

  canAddTeams(): boolean {
    return (this.isSiteAdmin() || this.platformPermission.isRegAdmin);
  }

  isSiteAdmin(): boolean {
    if (this.platformPermission === null) {
      return false;
    }

    return this.platformPermission.isSuperUser || this.platformPermission.isSiteAdmin;
  }

  isSuperUser() {
    if (this.platformPermission === null) {
      return false;
    }

    return this.platformPermission.isSuperUser;
  }

  showAddGroup(): void {
    this.matDialog.open(AddGroupComponent, {
      width: '500px',
      disableClose: true,
      panelClass: this.componentCssClass,
    });
  }

  showAddTeam(): void {
    this.matDialog.open(CreateTeamComponent, {
      width: '500px',
      disableClose: true,
      panelClass: this.componentCssClass,
      data: {
        tenantId: this.tenantId,
        isSuperUser: this.isSuperUser()
      }
    });
  }

  showInviteUser(): void {
    this.matDialog.open(InviteUserComponent, {
      width: '500px',
      disableClose: true,
      panelClass: this.componentCssClass,
      data: {
        tenantId: this.tenantId
      }
    });
  }
}
