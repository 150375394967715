import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import moment from 'moment';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  LocationType,
  WeatherResponse,
  WeatherMetric,
  WeatherFormat
} from '@weather/models';

import { WindDirectionService } from './wind-direction.service';

@Injectable()
export class WeatherService {
  constructor(
    private http: HttpClient,
    private windDirectionService: WindDirectionService
  ) {}

  /**
   * Retrieves the weather forecast for a given location type (farm, field, plot), slug and number of days
   * @param type entity type -farm, field, plot, ...-
   * @param slug entity slug
   * @param days number of days for the forecast
   */
  getForecast(
    type: LocationType,
    slug: string,
    days: number
  ): Observable<WeatherResponse> {
    const today = moment();
    return this.getWeather(
      'forecast',
      type,
      slug,
      today.toDate(),
      today.add(days, 'days').toDate(),
      WeatherMetric.PRECIPITATION,
      WeatherMetric.TEMPERATURE,
      WeatherMetric.WIND_SPEED,
      WeatherMetric.WIND_DIRECTION,
      WeatherMetric.PRESSURE,
      WeatherMetric.WEATHER_TYPE,
      WeatherMetric.DEW_POINT,
      WeatherMetric.RELATIVE_HUMIDITY,
      WeatherMetric.REF_ET_PENMAN
    );
  }

  getWeather(
    format: WeatherFormat,
    type: LocationType,
    slug: string,
    startDate: Date,
    endDate: Date,
    ...metrics: WeatherMetric[]
  ): Observable<WeatherResponse> {
    const params = new HttpParams()
      .append('start_date', moment(startDate).format('YYYY-MM-DD'))
      .append('end_date', moment(endDate).format('YYYY-MM-DD'))
      .append('service_name', 'METEOMATICS-ACTUAL_AND_FORECAST')
      .append('account_key', 'kisanhub-account')
      .append('parameters', metrics.join(','));
    return this.http
      .get<WeatherResponse>(`/api/weather/${format}/${type}/${slug}`, {
        params
      })
      .pipe(
        map((res: any) => {
          const mapped = {
            ...res.data,
            lastUpdate: res.metadata.last_updated,
            sourceAttribution: res.metadata.source
          };
          if (mapped.data) {
            mapped.data.daily.forEach(daily => {
              daily.wind_direction = this.windDirectionService.interpret(
                daily.mode_wind_direction
              );
            });
          }
          return mapped;
        })
      );
  }
}
