import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CheckClientAndTenantService {

  constructor() { }

  isClientIndia(clientId: string): boolean {
    return clientId === 'india';
  }


  isClientUK(clientId: string): boolean {
    return clientId === 'uk';
  }


  isTenantNiab(tenantId: string): boolean {
    return tenantId === 'niab';
  }


  getTenantTheme(tenantId: string): string {
    return tenantId + '-theme';
  }
}
