import {Component, Input} from '@angular/core';
import { FieldStats } from '@shared/charts/field-stats/models/field-stats.model';

@Component({
    selector: 'app-field-stats-chart-st',
    template: require('./field-stats-chart-st.component.html'),
    styles: [require('./field-stats-chart-st.component.scss')]
})
export class FieldStatsChartStComponent  {
  @Input() stats: FieldStats;

  constructor() { }

    isEmpty(fieldStat: FieldStats): boolean {
        return !fieldStat || fieldStat.plots === 0;
    }
}
