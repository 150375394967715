import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Variety } from '@shared/core/models/variety.model';
import { Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable()
export class VarietyService {
  constructor(private http: HttpClient) {}

  public varietySaved$: Subject<Variety> = new Subject<Variety>();

  query(cropId?: number, search?: string): Observable<Variety[]> {
    let params = new HttpParams();
    if (cropId) {
      params = params.append('genus_id', cropId.toString());
    }

    if (search) {
      params = params.set('search', search);
    }
    return this.http.get<Variety[]>('api/variety/', { params });
  }

  save(variety: Variety): Observable<Variety> {
    return this.http.post<Variety>('/api/variety/', variety).pipe(
      tap(v => {
        this.varietySaved$.next(v);
      })
    );
  }
}
