import { HttpParams } from '@angular/common/http';

export function copyIntegerParams(
  keys: string[],
  params: HttpParams,
  source: any
): HttpParams {
  keys.forEach(key => {
    if (source[key]) {
      params = params.set(key, source[key].toString());
    }
  });
  return params;
}

export function copyIntegerArrayParams(
  keys: string[],
  params: HttpParams,
  source: any
): HttpParams {
  keys.forEach(key => {
    if (source[key]) {
      params = params.set(key, source[key].join(','));
    }
  });
  return params;
}
