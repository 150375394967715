import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FileAttachmentsComponent } from './file-attachments.component';
import {
  MatButtonModule,
  MatListModule,
  MatProgressBarModule
} from '@angular/material';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FlexLayoutModule } from '@angular/flex-layout';
import { UploadService } from './upload.service';
import { HttpClientModule } from '@angular/common/http';
import { FileSizePipe } from './filesize.pipe';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { EmptyStateModule } from '@shared/components/empty-state/empty-state.module';
import { ShortFilePipe } from './shortfile.pipe';

@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    MatListModule,
    FlexLayoutModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatProgressBarModule,
    MatCardModule,
    MatIconModule,
    EmptyStateModule
  ],
  declarations: [FileAttachmentsComponent, FileSizePipe, ShortFilePipe],
  exports: [FileAttachmentsComponent],
  entryComponents: [FileAttachmentsComponent],
  providers: [UploadService]
})
export class UploadModule {}
