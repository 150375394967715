import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ProfileEditComponent } from '@settings/profile/profile-edit/profile-edit.component';
import { PlatformSettingsService, PlatformSettings } from '@shared/core';


@Component({
    selector: 'app-profile',
    template: require('./profile.component.html'),
    styles: [require('./profile.component.css')],
})
export class ProfileComponent implements OnInit {
    @Input() slug: string;
    tenant: string;

    constructor(
        public dialog: MatDialog,
        private platformSettingsService: PlatformSettingsService
        ) {
    }

    ngOnInit() {
        this.platformSettingsService.getAvailableSettings()
            .subscribe((val: PlatformSettings) => {
                this.tenant = val.TENANT_ID;
            });
    }

    openDialog(): void {
        const dialogRef = this.dialog.open(ProfileEditComponent, {
            width: '500px',
            data: {slug: this.slug, tenant: this.tenant},
        });
        dialogRef.afterClosed().subscribe((result) => {
        });
    }

}

