import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { UploadFileDetails } from './upload-file-details.model';
import { UploadedFileModel } from './uploaded-file.model';
import { defaultIfEmpty } from 'rxjs/operators';

@Injectable()
export class UploadService {
  constructor(private http: HttpClient) {}

  /**
   * Upload a list of files. Returns a single fork-joined observable. Observable is piped through
   * defaultIfEmpty so it will always return at least null
   */
  public upload(files: UploadFileDetails[]): Observable<UploadedFileModel[]> {
    const posts = [];
    if (files) {
      files.forEach(fd => {
        if (fd.file) {
          const formData = new FormData();
          formData.append('file', fd.file);
          formData.append('file_name', fd.file_name);
          posts.push(
            this.http.post<UploadedFileModel>('/api/upload/media/', formData)
          );
        }
      });
    }
    return forkJoin(posts).pipe(defaultIfEmpty(null));
  }
}
