import { Injectable, LOCALE_ID, Inject } from '@angular/core';
import { formatDate } from '@angular/common';
import { saveAs } from 'file-saver';
import { isDate, isNull } from 'util';
import { FileExporter } from '../models/file-exporter.model';

@Injectable()
export class CsvFileExporterService implements FileExporter {

  constructor(@Inject(LOCALE_ID) private locale: string) { }

  export(headers: string[], rows: object[][], fileName: string): void {
    const separator = ',';
    const cleaner = (key: string, value: any) => isNull(value) ? '' : value;

    const csv = rows.map(row => {
      return row.map(cell => {
        let value: string;
        if (isDate(cell)) {
          value = formatDate((cell as Date), 'yyyy-MM-dd', this.locale);
        } else {
          value = JSON.stringify(cell, cleaner);
        }

        return value;
      }).join(separator);
    });
    csv.unshift(headers.join(separator));

    const csvArray = csv.join('\r\n');
    const blob = new Blob([csvArray], {type: 'text/csv' });
    saveAs(blob, fileName || 'myFile.csv');
  }
}
