import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule} from '@angular/forms';

import { MaterialModule } from '@shared/material';
import { InviteUserComponent } from './invite-user/invite-user.component';
import { InviteUserService } from './services';

@NgModule({
  declarations: [InviteUserComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule
  ],
  providers: [
    InviteUserService
  ]
})
export class InviteUserModule { }
