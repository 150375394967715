import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { GrowingSeason } from '@shared/core';
import { Observable } from 'rxjs';

@Injectable()
export class GrowingSeasonService {

  constructor(private http: HttpClient) { }

  getCurrent(): Observable<GrowingSeason> {
    return this.http.get<GrowingSeason>('/api/v1.0/khutils/currentplantingseason/');
  }
}
