import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {SoilReportComponent} from './soil-report.component';
import {MaterialModule} from '@shared';
import {SoilReportSummaryDataSource} from './models/soil-report-summary-data-source.service';
import {EmptyStateModule} from '@shared/components/empty-state/empty-state.module';
import {ProgressSpinnerModule} from '@shared/progress-spinner';
import {CoreModule} from '@shared/core';
import {SoilReportUpdateService} from './soil-report-update.service';
import {UploadModule} from '../upload/upload.module';

@NgModule({
  declarations: [SoilReportComponent],
  imports: [
    CommonModule,
    MaterialModule,
    EmptyStateModule,
    ProgressSpinnerModule,
    EmptyStateModule,
    CoreModule,
    UploadModule
  ],
  entryComponents: [
      SoilReportComponent
  ],
  providers: [SoilReportSummaryDataSource, SoilReportUpdateService,
      {
          provide: 'JsSoilReportDialogService',
          useFactory: (i: any) => i.get('SoilReportDialogService'),
          deps: ['$injector']
      }]
})
export class SoilReportModule { }
