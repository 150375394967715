import { WeatherParameter } from './weather-parameter.model';
import { HourlyWeather } from '../hourly-weather.model';
import * as Highcharts from 'highcharts';

/**
 * Represents the Relative Humidity weather parameter
 */
export class RelativeHumidityWeatherParameter extends WeatherParameter {
  name = 'relative_humidity';
  description = 'Relative Humidity';

  convert(items: HourlyWeather[]): Highcharts.DataPoint[] {
    let res = [];
    if (items) {
      res = items.map(item => {
        return {
          x: new Date(item.timestamp).getTime(),
          y: Number(item.relative_humidity)
        };
      });
    }
    return res;
  }

  getYaxis(): Highcharts.AxisOptions {
    return {
      title: {
        text: 'Relative Humidity',
        style: {
          color: '#CC834A'
        }
      },
      labels: {
        formatter() {
          return this.value + ' %';
        },
        style: {
          color: '#CC834A'
        }
      },
      max: 100,
      opposite: true
    };
  }

  getSeries(): Highcharts.IndividualSeriesOptions {
    return {
      name: 'Relative Humidity',
      color: '#CC834A',
      type: 'spline',
      data: [],
      marker: {
        enabled: false
      },
      tooltip: {
        valueSuffix: ' %',
        valueDecimals: 2
      }
    } as Highcharts.SplineChartSeriesOptions;
  }
}
