import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

import { ProfileService, PersonalProfile } from '@settings/shared';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-user-details-modal',
  template: require('./user-details-modal.component.html'),
  styles: [require('./user-details-modal.component.scss')]
})
export class UserDetailsModalComponent implements OnInit {
  profileInfo$: Observable<PersonalProfile>;

  constructor(
    private profileService: ProfileService,
    public dialogRef: MatDialogRef<UserDetailsModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    this.profileInfo$ = this.profileService.getPersonalProfileInfo(this.data.userSlug);
  }


  closeModal(): void {
    this.dialogRef.close();
  }


  hasAddress(profile: PersonalProfile): boolean {
    return (profile && (profile.taluka || profile.district || profile.state || profile.pin_code)) ? true : false;
  }

}
