import {
  Component,
  OnInit,
  ViewEncapsulation,
  Input,
  OnChanges
} from '@angular/core';
import {
  WeatherResponse,
  WeatherParameter,
  HourlyWeather
} from '@weather/models';
import { WeatherParameterService } from '@weather/services/weather-parameter.service';

@Component({
  selector: 'app-daily-weather-viewer',
  template: require('./daily-weather-viewer.component.html'),
  styles: [require('./daily-weather-viewer.component.scss')],
  encapsulation: ViewEncapsulation.None
})
export class DailyWeatherViewerComponent implements OnInit, OnChanges {
  @Input()
  weather: WeatherResponse;

  @Input()
  location: string;

  @Input()
  status: string;

  hourlyWeather: HourlyWeather[];
  weatherParameters: WeatherParameter[];
  selectedParameters: WeatherParameter[];
  selectedDate: Date;
  showChartView = true;

  get chartIconColor(): string {
    return this.showChartView ? 'accent' : 'default';
  }
  get tableIconColor(): string {
    return !this.showChartView ? 'accent' : 'default';
  }


  constructor(private weatherParameterService: WeatherParameterService) {}

  ngOnInit() {
    this.weatherParameterService.query().subscribe(parameters => {
      this.weatherParameters = parameters;
      this.selectedParameters = [parameters[0], parameters[1], parameters[5]];
    });
  }

  ngOnChanges(): void {
    if (this.weather) {
      const date = this.weather.daily[0]
        ? this.weather.daily[0].date
        : new Date();
      this.loadHourlyDetail(date);
    } else {
      this.hourlyWeather = [];
    }
  }

  loadHourlyDetail(date: Date): void {
    this.selectedDate = date;
    this.hourlyWeather = this.weather.hourly.filter(
      // date_time and date are strings... O_O
      o =>
        new Date(o.date_time).toDateString() === new Date(date).toDateString()
    );
  }

  showChart() {
    this.showChartView = true;
  }

  showTable() {
    this.showChartView = false;
  }
}
