import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { WeatherResponse, HourlyWeather } from '@weather/models';
import { Observable } from 'rxjs';
import { WeatherParameter } from '@weather/models/weather-parameters/weather-parameter.model';
import { WeatherParameterService } from '@weather/services/weather-parameter.service';

@Component({
  selector: 'app-weather-detail-dialog',
  template: require('./weather-detail-dialog.component.html'),
  styles: [require('./weather-detail-dialog.component.scss')],
  encapsulation: ViewEncapsulation.None
})
export class WeatherDetailDialogComponent implements OnInit {
  weather: WeatherResponse;
  name: string;
  status: string;
  constructor(
    private dialogRef: MatDialogRef<WeatherDetailDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.weather = data.weather;
    this.name = data.name;
    this.status = data.status;
  }

  ngOnInit() {}

  close(): void {
    this.dialogRef.close();
  }
}
