/**
 * This enum represents the available list of metrics that can be requested in kisanmet
 */
export enum WeatherMetric {
  PRECIPITATION = 'precipitation',
  TEMPERATURE = 'temperature',
  WIND_SPEED = 'wind_speed',
  WIND_DIRECTION = 'wind_direction',
  PRESSURE = 'pressure',
  WEATHER_TYPE = 'weather_type',
  DEW_POINT = 'dew_point',
  RELATIVE_HUMIDITY = 'relative_humidity',
  REF_ET_PENMAN = 'ref_et_penman'
}
