import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { HourlyWeather, WeatherParameterChartable } from '@weather/models';
import * as Highcharts from 'highcharts';

@Component({
  selector: 'app-weather-detail-chart',
  template: require('./weather-detail-chart.component.html'),
  styles: [require('./weather-detail-chart.component.scss')]
})
export class WeatherDetailChartComponent implements OnInit, OnChanges {
  private options: Highcharts.Options;

  Highcharts: typeof Highcharts = Highcharts;
  shouldUpdate: boolean;

  get highChartOptions(): Highcharts.Options {
    return this.options;
  }
  set highChartOptions(options: Highcharts.Options) {
    this.options = options;
    this.shouldUpdate = true;
  }

  @Input()
  items: HourlyWeather[];

  @Input()
  parameters: WeatherParameterChartable[];

  constructor() {}

  ngOnInit() {}

  ngOnChanges(): void {
    this.highChartOptions = this.buildOptions(this.items, this.parameters);
  }

  /**
   * Generates the high chart options using the list of items and the parameters
   * @param items list of hourly weather objects
   * @param parameters list of weather parameters
   */
  buildOptions(
    items: HourlyWeather[],
    parameters: WeatherParameterChartable[]
  ): Highcharts.Options {
    const res: Highcharts.Options = this.getChartBaseConfig();
    if (parameters) {
      const yAxis: Highcharts.AxisOptions[] = [];
      const series: Highcharts.IndividualSeriesOptions[] = [];
      parameters.forEach((p, index) => {
        const options = p.generate(items);
        if (options) {
          options.series.yAxis = index;
          yAxis.push(options.yAxis);
          series.push(options.series);
        }
      });
      res.yAxis = yAxis;
      res.series = series;
    }

    return res;
  }

  /**
   * Return the base chart configuration
   */
  getChartBaseConfig(): Highcharts.Options {
    return {
      exporting: {
        enabled: false
      },
      lang: {
        noData: 'Loading Data...'
      },
      chart: {
        zoomType: 'xy'
      },
      credits: {
        enabled: false
      },
      title: {
        text: ''
      },
      xAxis: {
        type: 'datetime',
        labels: {
          format: '{value: %H:%M}'
        }
      },

      yAxis: [],
      tooltip: {
        shared: true
      },
      legend: {
        enabled: true,

        align: 'center',
        verticalAlign: 'top',
        floating: true,
        borderColor: '#CCC',
        borderWidth: 1,
        shadow: false
      },
      plotOptions: {
        series: { connectNulls: true }
      },
      series: []
    };
  }
}
