import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CsvFileExporterService } from './services/csv-file-exporter.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: [ CsvFileExporterService ]
})
export class FileExporterModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: FileExporterModule,
      providers: [
        CsvFileExporterService
      ]
    };
  }
 }

