import { Injectable } from '@angular/core';
import { of, Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import {
  PrecipitationWeatherParameter,
  TemperatureWeatherParameter,
  WeatherParameter,
  WindSpeedWeatherParameter,
  RelativeHumidityWeatherParameter,
  PressureWeatherParameter,
  DewPointWeatherParameter
} from '@weather/models';

@Injectable()
export class WeatherParameterService {
  /**
   * List of weather parameters available to select and render as a chart series or column in the table
   */
  private availableParameters = [
    new PrecipitationWeatherParameter(),
    new TemperatureWeatherParameter(),
    new WindSpeedWeatherParameter(),
    new PressureWeatherParameter(),
    new DewPointWeatherParameter(),
    new RelativeHumidityWeatherParameter()
  ];

  query(): Observable<WeatherParameter[]> {
    return of(this.availableParameters).pipe(shareReplay());
  }
}
