import { Injectable } from '@angular/core';

@Injectable()
export class WindDirectionService {
  /**
   * This method interprets the wind direction code using common words.
   * eg:
   *    NE  -> From North East
   *    NNW -> From North North West
   * @param modeWindDirection wind direction code. eg: NE, NW, ...
   */
  interpret(modeWindDirection: string): string {
    const res = ['From'];
    for (const letter of modeWindDirection) {
      switch (letter) {
        case 'N':
          res.push('North');
          break;
        case 'S':
          res.push('South');
          break;
        case 'E':
          res.push('East');
          break;
        case 'W':
          res.push('West');
          break;
      }
    }
    return res.join(' ');
  }
}
