import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { MatAutocompleteModule, MatDialogModule, MatIconRegistry } from '@angular/material'; // TODO: Review
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { MaterialModule } from '@shared/material';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { CommonModule } from '@angular/common';

import { CoreModule } from '@shared/core';
import { SettingsComponent } from './settings.component';
import { ProfileComponent } from './profile/profile.component';
import { ProfileEditComponent } from '@settings/profile/profile-edit/profile-edit.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { InviteUserModule, InviteUserComponent } from './invite-user';
import { SecondaryNavbarComponent } from './secondary-navbar/secondary-navbar.component';
import { AddGroupModule, AddGroupComponent } from './add-group';
import { PersonalRelationshipService } from './shared';
import { UserDetailsModule } from './profile/user-details/user-details.module';
import { CreateTeamModule } from './create-team/create-team.module';
import { SitesService, RegionsService } from './shared';

@NgModule({
    declarations: [SettingsComponent, ProfileComponent, ProfileEditComponent, SecondaryNavbarComponent],
    imports: [
        CommonModule,
        FormsModule,
        MatDialogModule,
        MaterialModule,
        ReactiveFormsModule,
        MatAutocompleteModule,
        MatMomentDateModule,
        OverlayModule,
        InviteUserModule,
        CoreModule,
        AddGroupModule,
        UserDetailsModule,
        CreateTeamModule
    ],
    exports: [SettingsComponent],
    entryComponents: [
        SettingsComponent, ProfileComponent, ProfileEditComponent,
        InviteUserComponent, SecondaryNavbarComponent, AddGroupComponent
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    providers: [
        PersonalRelationshipService,
        SitesService,
        RegionsService
    ]
})
// TODO:  This doesn't work with mdi '../../../../khapps2/base/mdIcons/mdi.csvg'
export class SettingsModule {
      constructor(private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer) {
       matIconRegistry.addSvgIconSet(domSanitizer.bypassSecurityTrustResourceUrl(
           require('../../../../angular/src/assets/mdi_material_design.svg')));
   }
}




