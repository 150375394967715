import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges
} from '@angular/core';
import { FieldStats } from '../models/field-stats.model';

@Component({
  selector: 'app-field-stats-chart',
  template: require('./field-stats-chart.component.html'),
  styles: [require('./field-stats-chart.component.scss')]
})
export class FieldStatsChartComponent implements OnInit, OnChanges {
  @Input() item: FieldStats;

  plantedPlotsFraction = 0;
  harvestedPlotsFraction = 0;
  totalPlotsPercentage = 0;
  expectedYield = 0;

  constructor() {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.item && this.item) {
      if (this.item.plots !== 0) {
        this.plantedPlotsFraction = this.item.planted_plots / this.item.plots;
        this.harvestedPlotsFraction =
          this.item.harvested_plots / this.item.plots;
        this.totalPlotsPercentage =
          ((this.item.planted_plots + this.item.harvested_plots) * 100) /
          (this.item.plots * 2);
      } else {
        this.plantedPlotsFraction = 0;
        this.harvestedPlotsFraction = 0;
        this.totalPlotsPercentage = 0;
      }
      if (this.item.expected_production && this.item.cropped_area) {
        this.expectedYield =
          this.item.expected_production / this.item.cropped_area;
      } else {
        this.expectedYield = 0;
      }
    }
  }
}
