import { Injectable } from '@angular/core';
import { FarmFilter } from '@shared/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Crop } from '@shared/core/models/crop.model';

@Injectable({
  providedIn: 'root'
})
export class GenusVarietyService {
  constructor(private http: HttpClient) {}

  getCrops(farmFilter: FarmFilter): Observable<Crop[]> {
    let params = new HttpParams();

    ['growing_season', 'team_id', 'farm_id', 'field_id'].forEach(key => {
      if (farmFilter[key]) {
        params = params.append(key, farmFilter[key]);
      }
    });

    return this.http.get<Crop[]>('api/plot/genus-variety/', { params });
  }
}
