import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PesticideOperation } from '@shared/core/models/pesticide-operation';
import { PesticideOperationResults } from '@shared/core/models/pesticide-operation-results';
import {BaseOperation} from '@shared/core/models/base-operation';
import {Page} from '@shared/core/models/page';

@Injectable({
  providedIn: 'root'
})
export class OperationService {
  constructor(private http: HttpClient) {}

  savePesticide(operation: PesticideOperation): Observable<PesticideOperation> {
    return this.http.post<PesticideOperation>('/api/operation/', operation);
  }

  updatePesticide(
    operation: PesticideOperation
  ): Observable<PesticideOperation> {
    return this.http.put<PesticideOperation>(
      `/api/operation/${operation.id}/`,
      operation
    );
  }

  /**
   * Determine whether we can delete this Plot. This is true if there are no operations,
   * or only a single planned sowing operation
   * @param plot_id
   */
  canDeletePlot(plot_id: number) : Observable<boolean> {
    let params = new HttpParams().set('plot_id', plot_id.toString());
        return this.http.get<Page<BaseOperation>>('/api/operation/', {params}).pipe(
          map( (result) => {
            return result.total == 0 ||
                (result.total == 1 && result.results[0].type == 'sowing' && !result.results[0].is_applied);
          })
      )
  }

  /**
   * Return the most recent burndown operation for this plot
   */
  getBurndown(plotId: number): Observable<PesticideOperation> {
    const params = new HttpParams()
      .set('plot_id', plotId.toString())
      .set('is_burndown', 'true')
      .set('is_applied', 'true')
      .set('type', 'pesticide');

    return this.http
      .get<PesticideOperationResults>('/api/operation/', { params })
      .pipe(
        map(resp => {
          if (resp.total) {
            return resp.results[0];
          }
        })
      );
  }
}
