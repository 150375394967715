import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import { Region } from '../models';

@Injectable()
export class RegionsService {

  constructor(private http: HttpClient) { }

  getRegions(): Observable<Array<Region>> {
    return this.http.get<Array<Region>>('/api/v1.0/profiles/regions/');
  }
}
