import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Page} from '@shared/core/models/page';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {Farm} from '@shared/core/models/farm.model';
import {GenericQueryService} from '@shared/core/services/generic-query-service';

@Injectable({
  providedIn: 'root'
})
export class FarmService implements GenericQueryService {
  constructor(private http: HttpClient) {}

  query(search?: string, excludePlotShares?: boolean): Observable<Page<Farm>> {
    let params = new HttpParams();
    if (search) {
      params = params.append('search', search);
    }
    if (excludePlotShares) {
      params = params.append('exclude_plot_shares', 'yes');
    }
    return this.http.get<Page<Farm>>('/api/farm/', { params });
  }

  create(farm: Farm): Observable<Farm> {
    return this.http.post<Farm>('/api/farm/', farm);
  }

  update(farm: Farm): Observable<Farm> {
    return this.http.put<Farm>(`/api/farm/${farm.id}/`, farm);
  }

  get(id: number): Observable<Farm> {
    return this.http.get<Farm>(`/api/farm/${id}/`);
  }

  delete(id: number) {
    return this.http.delete(`/api/farm/${id}/`);
  }

  hasWritePermission(id: number): Observable<boolean> {
    return this.http
      .get<{ access: string }>(`/api/farm/${id}/permission/`)
      .pipe(map(permission => permission.access === 'read-edit'));
  }
}
