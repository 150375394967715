import {CollectionViewer} from '@angular/cdk/collections';
import {DataSource} from '@angular/cdk/table';
import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {BehaviorSubject} from 'rxjs/internal/BehaviorSubject';
import {catchError} from 'rxjs/operators';
import {SoilReportSummaryService} from '../services/soil-report-summary.service';
import {SoilReportSummary} from './soil-report-summary.model';
import {Page} from '@farm/field-inventory/field-inventory';

@Injectable()
export class SoilReportSummaryDataSource implements DataSource<SoilReportSummary> {
  private subject = new BehaviorSubject<SoilReportSummary[]>([]);

  public total = 0;
  public results$ = this.subject.asObservable();
  private lastPageFetched: number;
  private lastPageSize: number;

  constructor(private soilReportService: SoilReportSummaryService) {
  }

  connect(
      collectionViewer: CollectionViewer
  ): Observable<SoilReportSummary[]> {
    return this.results$;
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.subject.complete();
  }

  loadItems(
      teamId: number,
      pageNumber: number,
      pageSize: number
  ): void {
    this.soilReportService
      .getReports(teamId, pageNumber, pageSize)
      .pipe(catchError(() => of({ results: [], total: 0 })))
      .subscribe((page: Page<SoilReportSummary>) => {
        this.total = page.total;
        this.subject.next(page.results);
        this.lastPageFetched = pageNumber;
        this.lastPageSize = pageSize;
      });
  }
}
