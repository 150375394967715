import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {KhPdfViewerComponent} from '@shared/core/kh-pdf-viewer/kh-pdf-viewer.component';
import {PdfJsViewerModule} from 'ng2-pdfjs-viewer';


@NgModule({
    declarations: [KhPdfViewerComponent],
    imports: [
        CommonModule,
        PdfJsViewerModule
    ],
    entryComponents: [KhPdfViewerComponent]
})
export class KhPdfViewerModule {
}

