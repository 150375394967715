import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PersonalProfile } from '../';

@Injectable()
export class ProfileService {

  constructor(private http: HttpClient) { }

  getPersonalProfileInfo(userSlug: string): Observable<PersonalProfile> {
    return this.http.get<PersonalProfile>(`api/v1.0/profiles/personalprofile/${userSlug}`)
      .pipe(map((result: any): PersonalProfile => {
        return {
          full_name: result.full_name,
          email: result.email,
          displayable_picture: result.displayable_picture,
          mobile: result.mobile,
          location: result.location,
          company_name: result.company_name,
          job_title: result.job_title,
          country_name: result.country_name,
          farm_name: result.farm_name,
          taluka: result.taluka,
          district: result.district,
          state: result.state,
          pin_code: result.pin_code,
          primary_team: result.primary_team,
          subscribed_organization: result.subscribed_organization,
          tenant_prefix: result.tenant_prefix,
          enterprise_id: result.enterprise_id
        };
      }));
  }
}
