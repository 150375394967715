import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Team, TeamSelectionService, TeamService } from '@shared/core';
import { Observable } from 'rxjs';
import { debounceTime, map, startWith, switchMap } from 'rxjs/operators';
import {
  MatAutocompleteSelectedEvent,
  MatAutocompleteTrigger
} from '@angular/material/autocomplete';

@Component({
  selector: 'app-team-select',
  template: require('./team-select.component.html'),
  styles: [require('./team-select.component.scss')]
})
export class TeamSelectComponent implements OnInit {
  formControl = new FormControl();
  filteredTeams$: Observable<Team[]>;
  hasFocus: boolean;
  @ViewChild(MatAutocompleteTrigger) trigger;

  constructor(
    private teamService: TeamService,
    private selectedTeamService: TeamSelectionService
  ) {}

  ngOnInit() {
    this.filteredTeams$ = this.formControl.valueChanges.pipe(
      startWith([]),
      debounceTime(100),
      switchMap((value) =>
        this.teamService
          .getTeams(value.name ? value.name : value)
          .pipe(map((resp) => resp.results))
      )
    );

    this.selectedTeamService.get$().subscribe(team => {
      if (team) {
        this.formControl.setValue(team);
      }
    });
  }

  onSelected(event: MatAutocompleteSelectedEvent) {
    this.selectedTeamService.set(this.formControl.value);
  }

  onFocus() {
    this.hasFocus = true;
  }

  onBlur() {
    this.hasFocus = false;
    if (this.selectedTeamService.get$().getValue() && !this.formControl.value) {
      // switched to all teams
      this.selectedTeamService.set(null);
    }
  }

  displayName(team: Team): string {
    return team && team.name ? team.name : '';
  }
}
