import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { PlatformPermission } from '../models/platform-permission.model';

// TODO: move this part to angularjs constant or angular provider instead.
// It comes from Django and is being used automagically in AngularJs.
declare var permissions: any;

@Injectable()
export class PlatformPermissionService {

  constructor() { }

  /**
   * Return a PlatformPermission object with the info
   * of the permissions assigned to the current user
   */
  get(): Observable<PlatformPermission> {
    if (!permissions) {
      permissions = {};
    }

    return of({
      isSuperUser: permissions.is_superuser,
      isSiteAdmin: permissions.is_site_admin,
      isRegAdmin: permissions.is_reg_admin,
      isDssAdmin: permissions.is_dss_admin,
      isTeamAdmin: permissions.is_team_admin
    });
  }
}
