import { Injectable } from '@angular/core';
import { BaseEntity } from '@shared/core/models/base-entity.model';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class FarmSummaryService {
  constructor(private http: HttpClient) {}

  public getFarmsWithPlots(teamId?: number): Observable<BaseEntity[]> {
    let params = new HttpParams().set('exclude_no_plots', 'true');
    if (teamId) {
      params = params.append('team_id', teamId.toString());
    }

    return this.http.get<BaseEntity[]>('/api/farm/summary', { params });
  }
}
