import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SoilType } from '@shared/core/models/soil-type.model';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SoilTypeService {
  constructor(private http: HttpClient) {}

  query(): Observable<Array<SoilType>> {
    return this.http.get<Array<SoilType>>('/api/soil/type');
  }
}
