import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Subscription } from '@shared/core/models';

@Injectable()
export class SubscriptionService {

  constructor(private http: HttpClient) { }

  getSubscriptions(): Observable<Subscription[]> {
    return this.http.get<Subscription[]>('/api/v1.0/subscription-packages/light/');
  }
}
