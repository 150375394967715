import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Invitation } from '../models/invitation.model';
import { Observable } from 'rxjs';

@Injectable()
export class InviteUserService {

  constructor(private http: HttpClient) { }

  inviteUser(data: Invitation): Observable<object> {
    return this.http.post('/api/v1.0/profiles/invite-user/', data );
  }
}
