import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Field} from '@shared/core/models/field.model';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Page} from '@shared/core/models/page';
import {GenericQueryService} from '@shared/core/services/generic-query-service';
import {Farm} from '@shared/core/models/farm.model';
import {BaseEntity} from '@shared/core/models/base-entity.model';

@Injectable({
    providedIn: 'root'
})
export class FieldService implements GenericQueryService {
  constructor(private http: HttpClient) {
  }

  public get(id: number): Observable<Field> {
    return this.http.get<Field>(`/api/field/${id}/`);
  }

  public create(field: Field): Observable<Field> {
    return this.http.post<Field>('/api/field/', field);
  }

  public update(field: Field): Observable<Field> {
    return this.http.put<Field>(`/api/field/${field.id}/`, field);
  }

  public getFieldsForFarm(farm_id: number): Observable<Page<Field>> {
    const params = new HttpParams().set('farm_id', farm_id.toString());
    return this.http.get<Page<Field>>('/api/field/', {params});
  }

  public delete(id: number) {
    return this.http.delete(`/api/field/${id}/`);
  }

  public query(search?: string, excludePlotShares?: boolean): Observable<Page<BaseEntity>> {
    let params = new HttpParams();
    if (search) {
      params = params.append('search', search);
    }
    if (excludePlotShares) {
      params = params.append('exclude_plot_shares', 'yes');
    }
    return this.http.get<Page<Farm>>('/api/field/', {params});
  }
}
