import {
  AfterViewInit,
  Component,
  Inject,
  OnInit,
  ViewChild
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { merge } from 'rxjs';
import { environment } from '../../environments/environment';
import { SoilReportSummaryDataSource } from './models/soil-report-summary-data-source.service';
import { SoilReportSummary } from './models/soil-report-summary.model';
import { SoilReportUpdateService } from './soil-report-update.service';
import { TeamSelectionService } from '@shared/core/services/team-selection.service';

@Component({
  selector: 'app-soil-report',
  template: require('./soil-report.component.html'),
  styles: [require('./soil-report.component.scss')]
})
export class SoilReportComponent implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  showEmptyState: boolean;
  columnsToDisplay = ['action', 'team', 'date', 'sample', 'field'];
  pageSize = 50;
  pageSizeOptions = environment.pagination.options;

  constructor(
    @Inject('JsSoilReportDialogService') private dialogService: any,
    private dataSource: SoilReportSummaryDataSource,
    private selectedTeamService: TeamSelectionService,
    private updateService: SoilReportUpdateService
  ) {}

  ngOnInit() {
    this.dataSource.results$.subscribe((tableRows: SoilReportSummary[]) => {
      this.showEmptyState = tableRows.length === 0;
    });
  }

  showEditDialog(id: number) {
    this.dialogService.showForId(id);
  }

  ngAfterViewInit() {
    merge(
      this.paginator.page,
      this.updateService.updatedReportId$,
      this.selectedTeamService.get$()
    ).subscribe(result => {
      const team = this.selectedTeamService.get$().getValue();
      this.dataSource.loadItems(
        team ? team.id : null,
        this.paginator.pageIndex + 1,
        this.paginator.pageSize
      );
    });
  }
}
