import {Injectable} from '@angular/core';

import {Team} from '../models';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Page} from '@shared/core/models/page';

@Injectable({
  providedIn: 'root'
})
export class TeamService {

  constructor(private http: HttpClient) { }

  getLegacyTeams(serachTeam: string): Observable<Array<Team>> {
    const params = new HttpParams().append('search', serachTeam);

    return this.http.get<Array<Team>>('/api/v1.0/profiles/team/', { params })
      .pipe(map((teams: Array<Team>) => {
        return teams.map((team: any): Team => {
          return {
            id: team.id,
            slug: team.team_slug,
            name: team.team_name
          };
        });
      }));
  }

  getTeams(search?: string, excludePlotShares?: boolean): Observable<Page<Team>> {
    let params = new HttpParams();
    if (search) {
      params = params.set('search', search);
    }
    if (excludePlotShares) {
      params = params.set('exclude_plot_shares', 'true');
    }
    return this.http.get<Page<Team>>('/api/team/', {params});
  }

  getTeam(id: number): Observable<Team> {
    return this.http.get<Team>(`/api/team/${id}/`);
  }
}
