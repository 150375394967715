import {Component, HostBinding, Inject, OnDestroy, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Country, Profile, Subscription, Team, UserPermission} from '@shared/core/models';
import {Observable, Subject} from 'rxjs';
import {debounceTime, distinctUntilChanged, filter, map, startWith, switchMap, takeUntil, tap} from 'rxjs/operators';
import {
    ApplicationService,
    CheckClientAndTenantService,
    CountryService,
    ProfileService,
    SubscribedOrganization,
    SubscriptionService
} from '@shared/core';
import {DialogData} from './dialogdata.model';
import {OverlayContainer} from '@angular/cdk/overlay';
import {Application} from '@shared/core/models/application.model';
import {TeamService} from '@shared/core/services/team.service';
import {ErrorHandlerService} from '@shared/core/services/error-handler.service';


@Component({
    selector: 'app-profile-edit',
    template: require('./profile-edit.component.html'),
    styles: [require('./profile-edit.component.scss')],
    providers: [CountryService, ProfileService, SubscriptionService, ApplicationService]
})
export class ProfileEditComponent implements OnInit, OnDestroy {
    constructor(
        public dialogRef: MatDialogRef<ProfileEditComponent>,
        @Inject(MAT_DIALOG_DATA) public dialogData: DialogData,
        private countryService: CountryService, private profileService: ProfileService,
        private subscriptionService: SubscriptionService,
        private applicationService: ApplicationService,
        public overlayContainer: OverlayContainer,
        private teamService: TeamService,
        private errorHandlerService: ErrorHandlerService,
        private checkClientTenantService: CheckClientAndTenantService,
        public snackBar: MatSnackBar
    ) {
        if (this.isTenantNiab()) {
            this.componentCssClass = this.checkClientTenantService.getTenantTheme(this.dialogData.tenant);
            this.overlayContainer.getContainerElement().classList.add(this.componentCssClass);
        }
    }
    @HostBinding('class') componentCssClass;
    editUserForm: FormGroup;
    niabMembershipForm: FormGroup;
    countries: Country[];
    filteredCountries: Observable<Country[]>;
    profile: Profile;
    startDate: Date;
    allSubscriptions: Subscription[];
    allPermissions: UserPermission[];
    allApplications: Application[];
    updateResult: Profile;
    currentSite: string;
    professions = ['Agronomist', 'Grower', 'Staff'];
    teams: Array<Team> = [];
    private unsubscribeObservables$: Subject<void> = new Subject();
    fetchingTeams = false;

    ngOnInit() {
        this.profileService.getProfile(this.dialogData.slug).subscribe(profile => {
            this.currentSite = profile.site_name;
            this.initProfileForm(profile);
            if (this.isSiteNiab()) {
                this.editUserForm.addControl('primary_team', new FormControl('', Validators.required));
                this.fetchTeams(profile.primary_team);
                this.initNiabMembershipForm(profile.subscribed_organization);
                this.editUserForm.get('mobile').setValidators(Validators.pattern('[0-9]{1,10}'));
                this.editUserForm.get('countryName').clearValidators();
            }
            this.countryService.getCountries().subscribe(countries => {
                this.countries = countries;
                this.filteredCountries = this.editUserForm.get('countryName').valueChanges.pipe(
                  startWith(''), map(value => this._filter(value))
                );
            });
        });
        this.subscriptionService.getSubscriptions().subscribe(subscriptions => this.allSubscriptions = subscriptions);
        this.profileService.getUserPermissions().subscribe(permissions => this.allPermissions = permissions);
        this.applicationService.getApplications().subscribe(applications => this.allApplications = applications);
    }

    initProfileForm(profile: Profile) {
        this.editUserForm = new FormGroup({
            firstName: new FormControl(profile.first_name, Validators.required),
            lastName: new FormControl(profile.last_name, Validators.required),
            email: new FormControl(profile.email, [Validators.required, Validators.email]),
            countryName: new FormControl(profile.country_name, Validators.required),
            dialCode: new FormControl({value: profile.dial_code, disabled: true}, Validators.required),
            countryCode: new FormControl({value: profile.country, disabled: true}, Validators.required),
            mobile: new FormControl(
              profile.mobile ? profile.mobile.replace(profile.dial_code, '') : null,
              [Validators.required, Validators.pattern('[0-9]{1,10}')]
            ),
            company: new FormControl(profile.company_name),
            jobTitle: new FormControl(profile.job_title),
            profession: new FormControl(profile.profession),
            permission: new FormControl(profile.permission),
            subscription: new FormControl(profile.subscription_package),
            applications: new FormControl(profile.list_of_applications),
            sharingNotification: new FormControl(profile.sharing_notification),
        });
    }

    initNiabMembershipForm(subscribedOrganizations) {
        /**
         * Initialize NIAB membership form with values if profile has 'NIAB' in subscribedOrganizations.
         */
        let niabMembership: SubscribedOrganization = subscribedOrganizations.find(membership => {
            if (membership.name === 'NIAB') { return membership; }
        });
        if (!niabMembership) {
            niabMembership = {
                id: null,
                name: null,
                member_id: null,
                membership_start_date: null,
                membership_end_date: null,
            };
        }

        this.niabMembershipForm = new FormGroup({
            id: new FormControl(niabMembership.id),
            memberId: new FormControl(niabMembership.member_id, Validators.required),
            memberStartDate: new FormControl({
                value: (niabMembership.membership_start_date) ? new Date(niabMembership.membership_start_date) : null,
                disabled: true
            }),
            memberEndDate: new FormControl(
              (niabMembership.membership_end_date) ? new Date(niabMembership.membership_end_date) : null),
        });
    }

    isSiteNiab(): boolean {
        return this.currentSite === 'NIAB Network';
    }

    isTenantNiab(): boolean {
        return this.checkClientTenantService.isTenantNiab(this.dialogData.tenant);
    }

    setDialCode(item: Country): void {
        this.editUserForm.get('dialCode').setValue(item.dial_code);
        this.editUserForm.get('countryCode').setValue(item.country_code);
    }

    createNiabMembershipPayload(): SubscribedOrganization {
        /**
         * Create payload from Niab Membership form to add/update SubscribedOrganization.
         */
        const niabMembership = {} as SubscribedOrganization;
        if (this.niabMembershipForm.value) {
            niabMembership.id = this.niabMembershipForm.value.id;
        }
        niabMembership.name = 'NIAB';
        niabMembership.member_id = this.niabMembershipForm.value.memberId;
        niabMembership.membership_end_date = (
            this.niabMembershipForm.value.memberEndDate ?
            this.niabMembershipForm.value.memberEndDate.toISOString().split('T')[0] :
            null
        );
        return niabMembership;
    }

    editUserProfile(): void {
        /**
         * Method create update 'user profile' payload and updates it.
         */
        let formData: any;
        let data: any;
        formData = this.editUserForm.value;

        data = {
            first_name: formData.firstName,
            last_name: formData.lastName,
            country_name: formData.countryName,
            country: this.editUserForm.get('countryCode').value,
            dial_code: this.editUserForm.get('dialCode').value,
            permission: formData.permission,
            company_name: formData.company,
            job_title: formData.jobTitle,
            profession: formData.profession,
            mobile: formData.mobile,
            email: formData.email,
            subscription_package: formData.subscription,
            list_of_applications: formData.applications,
            sharing_notification: formData.sharingNotification,
        };

        if (this.niabMembershipForm) {
            data.primary_team = formData.primary_team;
            data.subscribed_organization = [this.createNiabMembershipPayload()];
        }

        this.profileService.updateUserProfile(this.dialogData.slug, data)
            .subscribe(updatedProfile => {
                this.updateResult = updatedProfile;
                this.snackBar.open('Profile information has been updated successfully!', 'CLOSE');
                this.dialogRef.close();
            },
            (error) => {
                const defaultMessage = 'Something went wrong! Please contact support team.';
                let message = '';
                if (error.status === 400) {
                   message = Array.isArray(error.error) ? error.error[0] : defaultMessage;
                }
                message = message ? message : defaultMessage;
                this.snackBar.open(message, 'CLOSE');
            }
            );
    }

    fetchTeams(currentTeamId: number): void {
        let teamFieldInitialized = false;
        this.editUserForm.get('primary_team').valueChanges
          .pipe(
            takeUntil(this.unsubscribeObservables$),
            filter((val => isNaN(val))),
            startWith(''),
            debounceTime(500),
            distinctUntilChanged(),
            tap(() => this.fetchingTeams = true),
            switchMap((val: string) => this.teamService.getLegacyTeams(val)),
            tap(() => this.fetchingTeams = false)
          ).subscribe(
            (teams: Array<Team>) => {
                this.teams = teams;

                // Set the default team that was selected when the new user was created
                if (!teamFieldInitialized) {
                    this.editUserForm.get('primary_team').setValue(currentTeamId);
                    this.getTeamNameToDisplay(currentTeamId);
                    teamFieldInitialized = true;
                }
            },
            this.errorHandlerService.errorHandler
          );
    }

    getTeamNameToDisplay = (teamId: string | number): string | undefined => {
        if (!this.teams) {
          return undefined;
        }
        const selectedTeam = this.teams.find((obj: Team) => obj.id === teamId);
        return selectedTeam ? selectedTeam.name : undefined;
    }

    private _filter(value: string): Country[] {
        const filterValue = value.toLowerCase();
        return this.countries.filter((countryObj) => countryObj.name.toLowerCase().includes(filterValue));
    }

    ngOnDestroy(): void {
        this.unsubscribeObservables$.next();
        this.unsubscribeObservables$.complete();
    }
}
