import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Team } from '../models';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class TeamSelectionService {
  private readonly subject: BehaviorSubject<Team>;

  constructor(private http: HttpClient) {
    const sessionValue = sessionStorage.getItem('selected-team');
    if (sessionValue) {
      this.subject = new BehaviorSubject<Team>(JSON.parse(sessionValue));
    } else {
      this.subject = new BehaviorSubject<Team>(null);
    }
  }

  get$(): BehaviorSubject<Team> {
    return this.subject;
  }

  set(team: Team) {
    this.subject.next(team);
    sessionStorage.setItem('selected-team', JSON.stringify(team));
    // update the database to satisfy legacy APIs. Aim to remove this as all APIs should be stateless
    let slug: string;
    if (!team || !team.slug) {
      // "All Teams"
      slug = 'All Teams';
    } else {
      slug = team.slug;
    }
    this.http.put('/api/utils/team/selected/', { slug }).subscribe();
  }

  allTeamsSelected(): boolean {
    return !this.subject.getValue() || !this.subject.getValue().id;
  }

  getCurrentSlug(): string {
    return this.subject.getValue() ? this.subject.getValue().slug : null;
  }
  getCurrentId(): number {
    return this.subject.getValue() ? this.subject.getValue().id : null;
  }
}
