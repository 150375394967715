import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Group } from '../models/group.model';
import { Observable } from 'rxjs';

@Injectable()
export class GroupService {

  constructor(private http: HttpClient) { }

  add(group: Group): Observable<void> {
    return this.http.post<void>('/api/v1.0/profiles/regions/', group);
  }
}
