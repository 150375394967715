import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'shortfile'
})
export class ShortFilePipe implements PipeTransform {
  transform(name: string): string {
    const lastSlashPos = name.lastIndexOf('/');
    if (lastSlashPos < 1) {
      return name;
    }
    return name.substr(name.lastIndexOf('/') + 1);
  }
}
