import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Plot } from '@shared/core/models/plot.model';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Page } from '../models/page';

@Injectable({
  providedIn: 'root'
})
export class PlotService {
  constructor(private http: HttpClient) {}

  get(id: number): Observable<Plot> {
    return this.http.get<Plot>(`/api/plot/${id}/`);
  }

  delete(id: number)  {
    return this.http.delete(`/api/plot/${id}/`);
  }

  getForField(field_id: number): Observable<Page<Plot>> {
    const params = new HttpParams().set('field_id', field_id.toString());
    return this.http.get<Page<Plot>>('/api/plot/', {params});
  }

  query(search?: string): Observable<Page<Plot>> {
    let params = new HttpParams();
    if (search) {
      params = params.append('search', search);
    }
    return this.http.get<Page<Plot>>('/api/plot/', { params });
  }

  create(plot: Plot): Observable<Plot> {
    return this.http.post<Plot>('/api/plot/', plot);
  }

  update(plot: Plot): Observable<Plot> {
    return this.http.put<Plot>(`/api/plot/${plot.id}/`, plot);
  }

  hasWritePermission(id: number): Observable<boolean> {
    return this.http
      .get<{ access: string }>(`/api/plot/${id}/permission/`)
      .pipe(map(permission => permission.access === 'read-edit'));
  }
}
