import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material';

import { GroupService } from '../services/group.service';
import { Group } from '../models/group.model';

@Component({
  selector: 'app-add-group',
  template: require('./add-group.component.html'),
  styles: [require('./add-group.component.scss')]
})
export class AddGroupComponent implements OnInit {
  groupForm = this.fb.group({
    name: ['', Validators.required]
  });

  constructor(
    private groupService: GroupService,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<AddGroupComponent>) { }

  ngOnInit() {
  }

  save() {
    if (!this.groupForm.valid) {
      return;
    }

    const group = this.groupForm.value as Group;
    this.groupService.add(group).subscribe(() => this.cancel());
  }

  cancel() {
    this.dialogRef.close();
  }

}
