import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SoilReportSummary } from '../models/soil-report-summary.model';
import { Page } from '@farm/field-inventory/field-inventory';

@Injectable({
  providedIn: 'root'
})
export class SoilReportSummaryService {
  constructor(private http: HttpClient) {}

  getReports(
    teamId: number,
    pageNumber: number,
    pageSize: number
  ): Observable<Page<SoilReportSummary>> {
    let params = new HttpParams();
    params = params
      .append('page_size', String(pageSize))
      .append('page_number', String(pageNumber));
    if (teamId) {
      params = params.append('team_id', String(teamId));
    }
    return this.http.get<Page<SoilReportSummary>>('api/soil/report/summary/', {
      params
    });
  }
}
