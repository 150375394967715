import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { FieldStats } from '@shared/charts';
import { Observable } from 'rxjs';
import { DashboardFilter } from '@dashboard/shared/models/dashboard-filter.model';
import { copyIntegerParams } from '@shared/core/services/http-utils.service';

@Injectable()
export class FieldStatsService {
  constructor(private http: HttpClient) {}

  get(filter: DashboardFilter): Observable<FieldStats> {
    let params = new HttpParams().set('growing_season', filter.growing_season);

    params = copyIntegerParams(
      ['team_id', 'farm_id', 'genus_id', 'variety_id'],
      params,
      filter
    );
    return this.http.get<FieldStats>('/api/plot/inventory/stats/', { params });
  }
}
