import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stringReplace'
})
export class StringReplacePipe implements PipeTransform {

  transform(value: string, strToReplace: string = '', replacementStr: string = ''): string {
    if (value) {
      return value.replace(new RegExp(strToReplace, 'g'), replacementStr);
    }
    return '';
  }

}
