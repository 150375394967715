import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CreateTeamComponent } from './create-team/create-team.component';
import { CoreModule } from '@shared/core';
import { CreateTeamService } from './services';
import { MaterialModule } from '@shared';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [CreateTeamComponent],
  imports: [
    CommonModule,
    CoreModule.forRoot(),
    MaterialModule,
    ReactiveFormsModule
  ],
  entryComponents: [
    CreateTeamComponent
  ],
  providers: [
    CreateTeamService
  ]
})
export class CreateTeamModule { }
