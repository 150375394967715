import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { NewTeam } from '../models';

@Injectable()
export class CreateTeamService {

  constructor(private http: HttpClient) { }

  createTeam(data: NewTeam) {
    return this.http.post('/api/v1.0/profiles/team/', data);
  }
}
