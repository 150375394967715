import { HourlyWeather } from '../hourly-weather.model';
import * as Highcharts from 'highcharts';
import { object } from 'underscore';

export interface WeatherParameterChartable {
  generate(
    items: HourlyWeather[]
  ): {
    series: Highcharts.IndividualSeriesOptions;
    yAxis: Highcharts.AxisOptions;
  };
}

/**
 * Abstract class that implements WeatherParameterChartable interface.
 * Used as a base for the weather parameters available.
 */
export abstract class WeatherParameter implements WeatherParameterChartable {
  name: string;
  description: string;

  /**
   * Generates series and yAxis objects from a list of items
   * @param items list of hourlyWeather objects
   */
  generate(
    items: HourlyWeather[]
  ): {
    series: Highcharts.IndividualSeriesOptions;
    yAxis: Highcharts.AxisOptions;
  } {
    const points = this.convert(items) || [];
    const yPoints = points.map(o => o.y);
    return {
      yAxis: Object.assign(
        {},
        {
          max: yPoints.reduce((a, b) => Math.max(a, b), null),
          min: yPoints.reduce((a, b) => Math.min(a, b), null)
        },
        this.getYaxis()
      ),
      series: { ...this.getSeries(), data: points }
    };
  }

  /**
   * Generates the data points for the chart
   */
  abstract convert(items: HourlyWeather[]): Highcharts.DataPoint[];

  /**
   * Generates the yAxis configuration
   */
  abstract getYaxis(): Highcharts.AxisOptions;

  /**
   * Generates the series
   */
  abstract getSeries(): Highcharts.IndividualSeriesOptions;
}
