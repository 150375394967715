import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

import { ApplicationService, SubscriptionService, Subscription } from '@shared/core';
import { Application } from '@shared/core/models/application.model';
import { RegionsService, SitesService, Region, Site } from '@settings/shared';
import { CreateTeamService } from '../';
import { CheckClientAndTenantService } from '@shared/core/services/check-client-and-tenant.service';

@Component({
  selector: 'app-create-team',
  template: require('./create-team.component.html'),
  styles: [require('./create-team.component.scss')]
})
export class CreateTeamComponent implements OnInit {
  createTeamForm = this.fb.group({
    team_name: ['', Validators.required],
    list_of_applications: ['', Validators.required],
    regions: ['', Validators.required],
    subscription_package: ['', Validators.required],
    site: ['', Validators.required]
  });

  isCreatingTeam = false;
  listOfApplications: Observable<Array<Application>>;
  regions: Observable<Array<Region>>;
  subscriptionPackages: Observable<Array<Subscription>>;
  sites: Observable<Array<Site>>;
  tenantId = '';

  constructor(
      private fb: FormBuilder,
      private applicationsService: ApplicationService,
      private subscriptionService: SubscriptionService,
      private regionsService: RegionsService,
      private sitesService: SitesService,
      private dialog: MatDialogRef<CreateTeamComponent>,
      private snackBar: MatSnackBar,
      private createTeamService: CreateTeamService,
      private checkClientTenantService: CheckClientAndTenantService,
      @Inject(MAT_DIALOG_DATA) public dialogData: any
    ) { }


  ngOnInit(): void {
    this.tenantId = this.dialogData.tenantId;

    // If user is not superUser, hide the select 'site' option
    if (this.dialogData && !this.dialogData.isSuperUser) {
      this.createTeamForm.removeControl('site');
    }

    if (this.isTenantNiab()) {
      this.createTeamForm.removeControl('list_of_applications');
      this.createTeamForm.removeControl('subscription_package');
    } else {
      this.listOfApplications = this.applicationsService.getApplications().pipe(catchError(this.errorHandler));
      this.subscriptionPackages = this.subscriptionService.getSubscriptions().pipe(catchError(this.errorHandler));
    }

    this.regions = this.regionsService.getRegions().pipe(catchError(this.errorHandler));
    this.sites = this.sitesService.getSites().pipe(catchError(this.errorHandler));
  }


  save(): void {
    if (!this.createTeamForm.valid) {
      return;
    }

    this.isCreatingTeam = true;
    const formData = this.createTeamForm.value;

    this.createTeamService.createTeam(formData)
      .pipe(finalize(() => this.isCreatingTeam = false ))
      .subscribe((res: any) => {
        this.dialog.close();
        this.snackBar.open('Team has been created successfully.', 'CLOSE');
      },
      (err: HttpErrorResponse) => {
        this.errorHandler(err);
      });
  }


  isTenantNiab(): boolean {
    return this.checkClientTenantService.isTenantNiab(this.tenantId);
  }


  closeDialog(): void {
    this.dialog.close();
  }


  errorHandler = (err: HttpErrorResponse) => {
    let message = '';
    message = err.error && err.error.team_name ? err.error.team_name[0] : 'Something went wrong, please contact support team.';
    this.snackBar.open(message, 'CLOSE');
    return of([]);
  }

}
