import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {UploadFileDetails} from './upload-file-details.model';
import * as _ from 'underscore';
import {UploadService} from './upload.service';
import {UploadedFileModel} from './uploaded-file.model';

@Component({
  selector: 'app-upload-file-attachments',
  template: require('./file-attachments.component.html'),
  styles: [require('./file-attachments.component.scss')]
})
export class FileAttachmentsComponent implements OnInit {
  @ViewChild('file') file: any;

  @Input() files: UploadedFileModel[];
  @Output() added = new EventEmitter<UploadedFileModel>();
  @Output() removed = new EventEmitter<UploadedFileModel>();

  uploading = false;

  constructor(private uploadService: UploadService) {}

  ngOnInit() {
    if (!this.files) {
      this.files = new Array<UploadFileDetails>();
    }
  }

  onFilesAdded() {

    Array.from<File>(this.file.nativeElement.files).forEach( file => {
      let f = new UploadFileDetails();
      f.file = file;
      f.file_name = file.name;

      const mimeType = f.file.type;
      if (mimeType.match(/image\/*/)) {
        const reader = new FileReader();
        reader.readAsDataURL(f.file);
        reader.onload = event => {
          f.url = reader.result.toString();
        };
      }
      this.uploading = true;
      this.uploadService.upload([f]).subscribe( (resp) => {
        this.uploading = false;
        this.added.emit(resp[0]);
        this.files.push(resp[0]);
      });
    });
  }

  removeFile(file: UploadedFileModel) {
    this.files = _.without(this.files, file);
    this.removed.emit(file);
  }

  addFiles() {
    this.file.nativeElement.click();
  }
}
