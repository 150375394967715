import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TeamSelectComponent} from '@shared/components/navbar/team-select/team-select.component';
import {MaterialModule} from '@shared';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

@NgModule({
  declarations: [TeamSelectComponent],
    imports: [
        CommonModule,
        MaterialModule,
        FormsModule,
        ReactiveFormsModule
    ],
    entryComponents: [TeamSelectComponent],
    exports: [TeamSelectComponent]
})
export class NavbarModule { }
