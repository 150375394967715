import {
  Component,
  OnInit,
  Input,
  OnChanges,
  HostBinding,
  ViewEncapsulation
} from '@angular/core';
import { LocationType, DailyWeather } from '@weather/models';
import { Observable } from 'rxjs';
import { WeatherService } from '@weather/services/weather.service';
import { map } from 'rxjs/operators';
import { WeatherDetailDialogComponent } from '@weather/weather-detail-dialog/weather-detail-dialog.component';
import { MatDialog } from '@angular/material';
import {
  PlatformSettingsService,
  CheckClientAndTenantService,
  PlatformSettings
} from '@shared/core';
import { OverlayContainer } from '@angular/cdk/overlay';

@Component({
  selector: 'app-weather-forecast-card',
  template: require('./weather-forecast-card.component.html'),
  styles: [require('./weather-forecast-card.component.scss')],
  encapsulation: ViewEncapsulation.None
})
export class WeatherForecastCardComponent implements OnInit, OnChanges {
  @HostBinding('class') componentCssClass: string;

  @Input()
  name: string;

  @Input()
  slug: string;

  @Input()
  type: LocationType;

  dailyWeather$: Observable<DailyWeather>;

  constructor(
    private weatherService: WeatherService,
    private matDialog: MatDialog,
    private platformSettingsService: PlatformSettingsService,
    private overlayContainer: OverlayContainer,
    private checkClientTenantService: CheckClientAndTenantService
  ) {}

  ngOnInit() {
    // This allows us to use the Tenant theme/class. eg. niab-theme
    this.platformSettingsService
      .getAvailableSettings()
      .subscribe((val: PlatformSettings) => {
        if (this.checkClientTenantService.isTenantNiab(val.TENANT_ID)) {
          this.componentCssClass = this.checkClientTenantService.getTenantTheme(
            val.TENANT_ID
          );
          this.overlayContainer
            .getContainerElement()
            .classList.add(this.componentCssClass);
        }
      });
  }

  ngOnChanges(): void {
    if (this.slug && this.type) {
      this.dailyWeather$ = this.weatherService
        .getForecast(this.type, this.slug, 0)
        .pipe(map(response => response.daily[0]));
    }
  }

  showDetails(date: Date): void {
    console.log(this.name);
    this.weatherService
      .getForecast(this.type, this.slug, 4)
      .subscribe(response => {
        this.matDialog.open(WeatherDetailDialogComponent, {
          maxWidth: '100vw',
          maxHeight: '100vh',
          height: '96%',
          width: '96%',
          disableClose: true,
          panelClass: [this.componentCssClass, 'no-overflow-dialog'],
          data: {weather: response, name: 'Local weather for ' + this.name, status: ''}
        });
      });
  }
}
