import {Component, Input, OnInit} from '@angular/core';
import {PlatformSettingsService} from '@shared/core/services/platform-settings.service';
import {PlatformSettings} from '@shared/core';

@Component({
    selector: 'app-kh-pdf-viewer',
    template: require('./kh-pdf-viewer.component.html'),
    styles: [require('./kh-pdf-viewer.component.scss')]
})
export class KhPdfViewerComponent implements OnInit {
    @Input()
    pdfSrc: string;
    viewerFolder: string;

    constructor(
        private platformSettingsService: PlatformSettingsService
    ) {
    }

    ngOnInit() {
        this.platformSettingsService.getAvailableSettings()
            .subscribe((val: PlatformSettings) => {
                this.viewerFolder = val.STATIC_URL + 'assets/lib/pdfjs-1.0';
            });
    }

}
