import { WeatherParameter } from './weather-parameter.model';
import { HourlyWeather } from '../hourly-weather.model';
import * as Highcharts from 'highcharts';

/**
 * Represents the DewPoint weather parameter
 */
export class DewPointWeatherParameter extends WeatherParameter {
  name = 'dew_point';
  description = 'Dew Point';

  convert(items: HourlyWeather[]): Highcharts.DataPoint[] {
    let res = [];
    if (items) {
      res = items.map(item => {
        return {
          x: new Date(item.timestamp).getTime(),
          y: Number(item.dew_point)
        };
      });
    }
    return res;
  }

  getYaxis(): Highcharts.AxisOptions {
    return {
      title: {
        text: 'Dew Point',
        style: {
          color: '#89A54E'
        }
      },
      labels: {
        formatter() {
          return this.value + '°C';
        },
        style: {
          color: '#89A54E'
        }
      },
      opposite: true
    };
  }

  getSeries(): Highcharts.IndividualSeriesOptions {
    return {
      name: 'Dew Point',
      type: 'spline',
      color: '#89A54E',
      yAxis: 1,
      data: [],
      marker: {
        enabled: false
      },
      tooltip: {
        valueSuffix: ' °C',
        valueDecimals: 2
      }
    } as Highcharts.SplineChartSeriesOptions;
  }
}
