import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable()
export class HeaderInterceptor implements HttpInterceptor {
    constructor() {}

    static getCookie(name): string {
        const cookies = document.cookie.split(';');
        for (const cookie of cookies) {
            const cookieValue = cookie.split('=');
            if (cookieValue[0].trim() === name) {
                return cookieValue[1];
            }
        }
        return '';
    }


    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        request = request.clone({ setHeaders: { 'Kisanhub-Client': 'ashes'}});
        const CSRF_REQUIRED = ['POST', 'DELETE', 'PUT', 'PATCH'];
        if (CSRF_REQUIRED.indexOf(request.method) >= 0) {
            const csrfToken = HeaderInterceptor.getCookie('csrftoken');
            request = request.clone({ setHeaders: { 'X-CSRFToken': csrfToken}});
        }
        return next.handle(request);
    }
}
