import { Injectable } from '@angular/core';

import { Genus } from '../models';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class GenusService {
  constructor(private http: HttpClient) {}

  query(search?: string, cropName?: string): Observable<Array<Genus>> {
    let params = new HttpParams();
    if (search) {
      params = params.set('search', search);
    }
    if (cropName) {
      params = params.set('crop_name', cropName);
    }
    return this.http.get<Array<Genus>>('/api/genus/', { params });
  }

  get(id: number): Observable<Genus> {
    return this.http.get<Genus>(`/api/genus/${id}/`);
  }
}
