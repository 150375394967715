import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { PersonalRelationship } from '../models';

@Injectable()
export class PersonalRelationshipService {

  constructor(private http: HttpClient) { }

  getPersonalRelationship(): Observable<Array<PersonalRelationship>> {
    return this.http.get<Array<PersonalRelationship>>('/api/v1.0/profiles/personal-relationships/');
  }
}
