import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { MatSnackBar } from '@angular/material';

@Injectable()
export class ErrorHandlerService {

  constructor(private snackBar: MatSnackBar) { }


  /**
   * Error handler for HTTP requests
   */
  errorHandler = (err: HttpErrorResponse) => {
    let message = '';
    message = err.error && err.error.message ? err.error.message : 'Something went wrong! Please contact support team.';
    this.snackBar.open(message, 'CLOSE');
  }
}
