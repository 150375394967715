import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import {
  PlatformPermissionModule,
  PlatformPermissionService
} from './platform-permission';

import {
  ApplicationService,
  CountryService,
  ErrorHandlerService,
  GrowingSeasonService,
  PlatformSettingsService,
  ProfileService,
  SubscriptionService,
  TeamSelectionService,
  TeamService
} from './services';
import { RoundUpPipe } from './pipes/round-up.pipe';
import { PadStartPipe } from './pipes/pad-start.pipe';
import { KhPdfViewerModule } from '@shared/core/kh-pdf-viewer/kh-pdf-viewer.module';
import { ProductsService } from '@shared/core/services/products.service';

@NgModule({
  declarations: [RoundUpPipe, PadStartPipe],
  imports: [
    CommonModule,
    HttpClientModule,
    PlatformPermissionModule,
    KhPdfViewerModule
  ],
  providers: [
    {
      provide: 'JsFarmFilterService',
      useFactory: (i: any) => i.get('FarmFiltersService'),
      deps: ['$injector']
    }
  ],
  exports: [RoundUpPipe, PadStartPipe]
})
export class CoreModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: CoreModule,
      providers: [
        ProfileService,
        ProductsService,
        CountryService,
        SubscriptionService,
        PlatformPermissionService,
        GrowingSeasonService,
        TeamService,
        PlatformSettingsService,
        ErrorHandlerService,
        TeamSelectionService,
        ApplicationService
      ]
    };
  }
}
