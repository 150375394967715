import { NgModule } from '@angular/core';

import { StringReplacePipe } from './string-replace-pipe/string.replace.pipe';

@NgModule({
  declarations: [
      StringReplacePipe
  ],
  exports: [
      StringReplacePipe
  ]
})
export class PipesModule { }
