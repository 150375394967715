import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Page} from '@shared/core/models/page';
import {Entity} from '@shared/core/models/entity.model';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ObservationReportService {

  constructor(private http: HttpClient) { }

  /**
   * Return the number of observations for this plot
   * @param plot_id
   */
  getCountForPlot(plot_id: number) : Observable<number> {
      let params = new HttpParams().set('plot_id', plot_id.toString());
      return this.http.get<Page<Entity>>('/api/observation/report/', {params}).pipe(
          map( (result) => {
            return result.total;
          })
      )
    }
}
