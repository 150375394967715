import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Profile, UserPermission } from '../models';

const headers = new HttpHeaders()
    .set('Content-Type', 'application/json');

@Injectable()
export class ProfileService {

  constructor(private http: HttpClient) { }

  get(): Observable<Profile> {
    return this.http.get<Profile>('/api/v1.0/profiles/personalprofile');
  }
  getProfile(slug: string): Observable<Profile> {
    return this.http.get<Profile>(`/api/v1.0/profiles/personalprofile/${slug}/`);
  }
  getUserPermissions(): Observable<UserPermission[]> {
    return this.http.get<UserPermission[]>('/api/v2.0/khutils/userpermission/');
  }
  updateUserProfile(slug: string, data: Profile): Observable<Profile> {
    return this.http.put<Profile>(`/api/v1.0/profiles/personalprofile/${slug}/`, JSON.stringify(data), {headers});
  }
}
