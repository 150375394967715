
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MaterialModule } from '@shared/material';
import { CoreModule } from '@shared/core';
import { UserDetailsModalComponent } from './user-details-modal/user-details-modal.component';
import { UserDetailsComponent } from './user-details.component';
import { ProfileService } from '@settings/shared';

@NgModule({
  declarations: [
    UserDetailsComponent,
    UserDetailsModalComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    CoreModule
  ],
  providers: [
    ProfileService
  ],
  entryComponents: [
    UserDetailsComponent, UserDetailsModalComponent
  ]
})
export class UserDetailsModule { }
