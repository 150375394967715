import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { PlatformSettings } from '../models';
import { shareReplay } from 'rxjs/operators';

@Injectable()
export class PlatformSettingsService {
  constructor(private http: HttpClient) {}

  getAvailableSettings(): Observable<PlatformSettings> {
    return this.http
      .get<PlatformSettings>('api/v1.0/khutils/platformsettings/')
      .pipe(shareReplay());
  }
}
