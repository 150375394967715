import {
  Component,
  OnInit,
  ViewEncapsulation,
  Input,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { HourlyWeather } from '@weather/models';
import { WeatherParameter } from '@weather/models/weather-parameters/weather-parameter.model';

@Component({
  selector: 'app-weather-detail-table',
  template: require('./weather-detail-table.component.html'),
  styles: [require('./weather-detail-table.component.scss')],
  encapsulation: ViewEncapsulation.None
})
export class WeatherDetailTableComponent implements OnInit, OnChanges {
  @Input()
  items: HourlyWeather[];

  @Input()
  parameters: WeatherParameter[];

  fixedColumns = ['date_time'];
  displayColumns: string[] = [...this.fixedColumns];
  /**
   * List of available column definitions in our template.
   * We need to do keep track of this because of two reasons:
   * - Allowing the WeatherParameter itself rendering its own template
   *   is the ideal solution, but requires extra work: it would be like using a tank to kill a fly.
   *   If required in future work, we might need to do something like: IWeatherParameterColumn.getColumnDef(): MatColumnDef
   * - If we don't keep this, we might end up with errors if one of our
   *   parameters has a name not defined explicitly in the template.
   */
  availableColumns = [
    'date_time',
    'wind_speed',
    'wind_direction',
    'precipitation',
    'temperature',
    'pressure',
    'dew_point',
    'relative_humidity'
  ];

  constructor() {}

  ngOnInit() {}
  ngOnChanges(changes: SimpleChanges): void {
    if (this.parameters && changes.parameters) {
      const cols = [...this.fixedColumns];
      this.parameters.forEach(p => {
        // We restrict the parameters to those that are available in our this component template (see MatColumnDef)
        if (this.availableColumns.indexOf(p.name) !== -1) {
          cols.push(p.name);
        }
      });
      this.displayColumns = cols;
    }
  }
}
