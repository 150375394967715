import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { DailyWeather } from '@weather/models';

@Component({
  selector: 'app-weather-card',
  template: require('./weather-card.component.html'),
  styles: [require('./weather-card.component.scss')],
  encapsulation: ViewEncapsulation.None
})
export class WeatherCardComponent implements OnInit {
  @Input()
  dailyWeather: DailyWeather;

  constructor() {}

  ngOnInit() {}
}
