import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule} from '@angular/forms';
import { MaterialModule } from '@shared/material';

import { GroupService } from './services/group.service';
import { AddGroupComponent } from './add-group/add-group.component';

@NgModule({
  declarations: [AddGroupComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule
  ],
  providers: [
    GroupService
  ]
})
export class AddGroupModule { }
