import { WeatherParameter } from './weather-parameter.model';
import { HourlyWeather } from '../hourly-weather.model';
import * as Highcharts from 'highcharts';

/**
 * Represents the Pressure weather parameter
 */
export class PressureWeatherParameter extends WeatherParameter {
  name = 'pressure';
  description = 'Pressure';

  convert(items: HourlyWeather[]): Highcharts.DataPoint[] {
    let res = [];
    if (items) {
      res = items.map(item => {
        return {
          x: new Date(item.timestamp).getTime(),
          y: Number(item.pressure)
        };
      });
    }
    return res;
  }

  getYaxis(): Highcharts.AxisOptions {
    return {
      title: {
        text: 'Pressure',
        style: {
          color: '#0092fe'
        }
      },
      labels: {
        formatter() {
          return this.value + ' hPa';
        },
        style: {
          color: '#0092fe'
        }
      },
      opposite: true
    };
  }

  getSeries(): Highcharts.IndividualSeriesOptions {
    return {
      name: 'Pressure',
      color: '#0092fe',
      type: 'spline',
      data: [],
      marker: {
        enabled: false
      },
      tooltip: {
        valueSuffix: ' hPa',
        valueDecimals: 2
      }
    } as Highcharts.SplineChartSeriesOptions;
  }
}
